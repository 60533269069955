<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="assess_content wrap bgw">
          <div class="titles">商品评价</div>
          <div class="box">
            <div class="fll">
              <img :src="goods.img" alt />
              <p class="one">{{goods.name}}</p>
            </div>
            <div class="frr">
              <textarea v-model="txt" placeholder="请留下您宝贵的意见及建议"></textarea>
              <!-- 评价 -->
              <div class="assets">
                <p @click="state =3">
                  <img v-show="state !==3" src="@/assets/image/Commodityevaluation_good@2x.png" alt />
                  <img
                    v-show="state ==3"
                    src="@/assets/image/Commodityevaluation_good_Selection@2x.png"
                    alt
                  />
                  好评
                </p>
                <p @click="state =2">
                  <img
                    v-show="state !==2"
                    src="@/assets/image/Commodityevaluation_Middle@2x.png"
                    alt
                  />
                  <img
                    v-show="state ==2"
                    src="@/assets/image/Commodityevaluation_Middle_Selection@2x.png"
                    alt
                  />
                  中评
                </p>
                <p @click="state =1">
                  <img
                    v-show="state !==1"
                    src="@/assets/image/Commodityevaluation_difference@2x.png"
                    alt
                  />
                  <img
                    v-show="state ==1"
                    src="@/assets/image/Commodityevaluation_difference_Selection@2x.png"
                    alt
                  />
                  差评
                </p>
              </div>
              <!-- 上传图片 -->
              <div class="imageBox">
                <el-upload
                  :action="imageUrl"
                  list-type="picture-card"
                  :on-success="ListImgSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-remove="ListImgRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
              <button @click="checking()">发表</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "../header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { submitComment } from "@/request/index";
import { usergetinfo } from "@/request/user";
import { getOrderDetails } from "@/request/index"; // 订单详情

export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      id: "", //订单ID
      data: [],
      goods: {},
      state: 3, // 评价
      imgList: [], //图片 list 地址
      txt: "", //评价 内容
      userList: [] // 个人信息
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.init();
    this.id = this.$route.query.did;
    this.id = Number(this.id)
    this.goods.img = this.$route.query.img;
    this.goods.name = this.$route.query.name;
  },
  methods: {
    start() {
      getOrderDetails({
        token: this.$tokens,
        id: this.id
      }).then(res => {
        this.data = res.data.datas;
      });
    },
    send() {
      let imgdata = this.imgList;
      let imgArr = [];
      let Athis = this;
      for (let i = 0; i < imgdata.length; i++) {
        imgArr.push(imgdata[i].url);
      }
      imgArr = imgArr.join("|");
      submitComment({
        token: this.$tokens,
        did: + this.id,
        star: this.state,
        msg: this.txt,
        img: imgArr
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "评价成功",
            type: "success"
          });
          setTimeout(function() {
            Athis.$router.replace({ path: "/user/allOrder" });
          }, 600);
        }
      });
    },
    checking() {
      if (!this.txt) {
        this.$message.error("请留下您宝贵的意见及建议");
      } else {
        this.send();
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //   资质证明 图的 list
    ListImgSuccess(response, file, fileList) {
      let imgurl = response.data.file.url;
      file.url = imgurl;
      this.imgList = fileList;
    },
    //   资质证明 删除
    ListImgRemove(file, fileList) {
      this.imgList = fileList;
    },
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
// 评价
.assess_content {
  min-height: 700px;
  border: 1px solid rgba(230, 230, 230, 1);

  .titles {
    height: 69px;
    line-height: 69px;
    padding-left: 36px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  .box {
    display: flex;
    padding-left: 98px;
    padding-top: 46px;

    > .fll {
      width: 430px;

      img {
        width: 430px;
        height: 430px;
      }

      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(26, 26, 26, 1);
        margin-top: 26px;
      }
    }

    > .frr {
      width: 600px;
      margin-left: 76px;
    }

    textarea {
      width: 460px;
      height: 226px;
      border: 1px solid rgba(168, 168, 168, 1);
      padding-top: 20px;
      text-indent: 20px;
      font-size: 16px;
    }

    .assets {
      display: flex;

      p {
        height: 30px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(168, 168, 168, 1);
        margin-right: 36px;

        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-right: 16px;
        }
      }
    }
  }

  .imageBox {
    width: 500px;
    max-height: 318px;
    overflow: hidden;
  }

  button {
    font-size: 18px;
    margin-top: 30px;
    width: 130px;
    height: 46px;
  }
}
</style>